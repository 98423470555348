<template lang="pug">
dropdown-filter(v-if='breakpoints.md' :label='label' :value='value' :disabled='disabled') 
  div(class='sm:min-w-[300px]' data-test='dropdown-filter-content')
    slot
  template(#footer)
    btn(v-if='value' link icon='rotate-ccw' data-test='btn-clear' @click='$emit("clear")') {{ $t('clear') }}
div(v-else class='flex w-full flex-col')
  collapse-item(:id='label || ""' :title='label')
    template(#head)
      span {{ label }}
      pill(v-if='value' class='ml-2' type='secondary') {{ value }}
    div(class='-mt-5')
      slot
    btn(v-if='value' link icon='rotate-ccw' data-test='btn-clear' @click='$emit("clear")') {{ $t('clear') }}
</template>

<script lang="ts">
import DropdownFilter from '../DropdownFilter.vue'
import { useCustomBreakpoints } from '@/composables/'
import { defineComponent } from 'vue'

const ListFilterDropdown = defineComponent({
  components: { DropdownFilter },
  props: {
    label: String,
    value: [String, Boolean, Number],
    dropdownMode: { type: Boolean, default: true },
    disabled: Boolean,
  },
  setup() {
    return {
      breakpoints: useCustomBreakpoints(),
    }
  },
})
export default ListFilterDropdown
</script>
