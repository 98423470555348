<template lang="pug">
div
  slot(:value='value' :change='change' :clear='clear')
</template>

<script setup lang="ts">
import { useListFilter } from './'

const props = defineProps<{
  initialValue?: string | boolean | string[]
  name: string
  interceptChange?: (name: string, value: any) => any
}>()

const { value, setProperty } = useListFilter(props.name, props.initialValue || '')
const change = (v: string) =>
  props.interceptChange ? setProperty(props.name, props.interceptChange(props.name, v)) : setProperty(props.name, v)
const clear = () => setProperty(props.name, undefined)
</script>
